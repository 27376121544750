class TimePicker {
  #dataContainer;
  #customShowtimeTimeout;

  checkAcceptance() {
    if ($('#accept_box').attr('data-accept-enable') === 'true') {
      var old_showtime = $('#accept_box').attr('data-old-showtime');
      var old_showtime_formatted = $('#accept_box').attr('data-old-showtime-formatted');
      var old_accepted = ($('#accept_box').attr('data-old-accepted') === 'true');
      var new_showtime = $('#showing_showtime').val();

      if (new_showtime == old_showtime || new_showtime == old_showtime_formatted) {
        if (old_accepted) {
          $('#showing_accepted').attr('checked', 'checked');
          LayoutUtils.hide(document.querySelector('#accept_box'));
        }

        return;
      }

      $('#showing_accepted').removeAttr('checked');
      LayoutUtils.show(document.querySelector('#accept_box'));
    }
  }

  showOrHideLockboxIcon() {
    // in this case icon is shown whenever lockbox showtime is set
    // indicated with showing_on_lockbox setting
    if($('#showing_on_lockbox').val() == 'true' && $('#shown-showtime').val() != '') {
      $('#lockbox_icon').show();
    } else {
      $('#lockbox_icon').hide();
    }
  }

  getParam(name) {
    return this.#dataContainer.dataset[name];
  }

  getBoolParam(name) {
    return this.#dataContainer.dataset[name] != '' ? JSON.parse(this.#dataContainer.dataset[name]) : false;
  }

  init() {
    this.#dataContainer = document.querySelector('.js-showtime-data');
    this.showOrHideLockboxIcon();

    const picker = document.querySelector('#available_showtimes');
    TimepickerBuilder.init(picker);
    if (this.getBoolParam('showtimeValuePresent') && !this.getBoolParam('showingOnLockbox')) {
      var value = $('#shown-showtime').val() || this.getParam('showtimeValue');
      if (picker) {
        picker.dispatchEvent(new CustomEvent('showtime-picker:setShowtime', {detail: {showtime: value}}));
      }
    }

    this.bindEvents(picker);

    $('#showtime_type').trigger('change');

    if (this.getBoolParam('disableShowtimeValidation')) {
      $('#showing_disable_showtime_validation').click();
    }

    const customDateInput = document.querySelector('#custom_showtime_date');
    if (customDateInput) {
      flatpickr('#custom_showtime_date', {dateFormat: 'M j, Y', defaultDate: customDateInput.dataset.defaultDate || 'today'});
    }
  } 

  changeShowTimeDelayed() {
    clearTimeout(this.#customShowtimeTimeout);
    $('#showing_submit').attr('disabled', 'disabled');
    this.#customShowtimeTimeout = setTimeout(() => {
      this.changeShowTime();
    }, 700);
  }

  bindEvents(picker) {
    $('#showing_on_lockbox').change(() => {
      this.showOrHideLockboxIcon();
    });

    if (this.getBoolParam('isLockboxPresent')) {
      $('#shown-showtime').change(this.showOrHideLockboxIcon);
    }

    if (picker) {
      picker.addEventListener('showtime:selected', (event) => {
        const {showtime, showtimeDisplay, surgeShowtime} = event.detail;
        $('#showing_showtime').val(showtime);
        $('#shown-showtime').val(showtimeDisplay);
        $('.showtime-info').removeClass('hidden-important');
        $('#showing_surge_showtime').val(surgeShowtime);
        $('#showing_on_lockbox').val('').trigger('change');
        $('#showtime_is_custom').val(0);
        if (showtimeDisplay.endsWith(this.getParam('nowShowtimeLabel'))) {
          $('#showing_lockbox_showtime').val(showtimeDisplay);
        } else {
          $('#showing_lockbox_showtime').val('');
        }
      });

      picker.addEventListener('showtime:selected', () => {this.selectShowTime();});
    }

    $('#showing_disable_showtime_validation').change(function() {
      var showtimeValidationDisabled = $(this).prop('checked');
      $('#notify_prospect_box, #confirmation_wrapper').toggle(!showtimeValidationDisabled);
      if (showtimeValidationDisabled) {
        $('#do_not_confirm').click();
      }
    });

    $('#showtime_type').bind('change', (e) => {
      const selectedOption = e.target.options[e.target.selectedIndex];
      if (!selectedOption) {
        return false;
      }

      if(selectedOption.value === 'available') {
        $('#showing_submit').removeAttr('disabled');
        $('#custom_showtime').hide();
        $('#lockbox_showtimes').hide();
        $('#available_showtimes').show();
        if (!this.getBoolParam('isLockboxPresent')) {
          this.hideLockboxOnlyElements();
        }
        if ($('#showing_disable_showtime_validation').prop('checked')) {
          $('#showing_disable_showtime_validation').click();
        }
        $('#do_not_confirm').show();
      } else if(selectedOption.value === 'custom') {
        $('#custom_showtime').show();
        $('#do_not_confirm').show();
        $('#available_showtimes').hide();
        $('#lockbox_showtimes').hide();
        $('.time_wrap .active').removeClass('active');
        this.changeShowTime();
        if (!this.getBoolParam('isLockboxPresent')) {
          this.hideLockboxOnlyElements();
        }
      } else if(selectedOption.value === 'lockbox') {
        $('#lockbox_showtimes').show();
        $('#custom_showtime').hide();
        $('#available_showtimes').hide();
        $('.time_wrap .active').removeClass('active');
        $('#do_not_confirm').hide();
        if ($('#do_not_confirm').hasClass('clicked')) {
          $('.confirmation_type').first().click();
        }
        showLockboxOnlyElements();
        document.querySelector('#lockbox_showtime_date').dispatchEvent(new Event('change'));
      }

      document.dispatchEvent(new CustomEvent('sm-modal:update-height'));
    });

    $('#custom_showtime_date, #custom_showtime_duration').bind('keyup change', () => {this.changeShowTimeDelayed();});
    $('#custom_showtime_time').bind('focusin', function() {$('#showing_submit').attr('disabled', 'disabled');});
    $('#custom_showtime_time').bind('focusout',() => {this.changeShowTimeDelayed();});
  }

  selectShowTime() {
    this.checkAcceptance();
    DashboardUtils.toggleEmailSchedule();
  }

  changeShowTime() {
    var timeFormat = /^(| )+\d{1,2}(:\d{2}|)(| )+(AM|PM|)$/i;
    if ($('#custom_showtime_date').val() && $('#custom_showtime_time').val().match(timeFormat)) {
      $('#custom_showtime_time').removeClass('show_time_error');
      this.selectShowTime();
      this.checkShowtime();
    } else {
      $('#showing_submit').removeAttr('disabled');
      if ($('#custom_showtime_time').val() !== undefined && $('#custom_showtime_time').val().length != 0)  {
        $('#custom_showtime_time').addClass('show_time_error');
      }
    }
  }

  checkShowtime(){
    $('#showtime_is_custom').val(1);
    $('#showing_on_lockbox').val('').trigger('change');
    $('#showing_lockbox_showtime').val('');
    if (!this.getBoolParam('isLockboxPresent')) {
      this.hideLockboxOnlyElements();
    }
    $.post('/check_showtime?listing_id=' + $('#showing_listing_id').val() + 
      '&date=' + $('#custom_showtime_date').val() + 
      '&time=' + $('#custom_showtime_time').val() + 
      '&duration=' + $('#custom_showtime_duration').val());
  }

  hideLockboxOnlyElements() {
    $('.question.only_for_lockbox:not(.js-previously-answered)').hide();
    $('.question.only_for_lockbox :input').attr('disabled', true);
    prospectVerificationMethod.render();
  }
}

export default TimePicker;